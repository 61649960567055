export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();

  if (process.server) return;

  if (!userStore.IS_AUTHORIZED && to.name !== "sign-in") {
    return navigateTo({ name: "sign-in" }, { redirectCode: 301 });
  }

  if (!userStore.IS_SIMPLE_DIMPLE && !userStore.IS_BIG_BOSS && to.name !== "home") {
    return navigateTo({ name: "home" }, { redirectCode: 301 });
  }

  if (
    ["users-wallets", "collections", "minters", "utilities", "settings", "nfts"].includes(to.name) &&
    !userStore.IS_BIG_BOSS
  ) {
    return navigateTo({ name: "home" }, { redirectCode: 301 });
  }
});
